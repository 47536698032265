import React, { useState, useCallback, useEffect } from 'react';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import { MdReplay } from 'react-icons/md';
import { Lesson } from '../../../types';
import debounce from 'lodash/debounce';
import { useLiveTutor } from '../../../hooks/LiveTutorProvider';
import NavigationArrows from '../../../components/NavigationArrows';
import StepIndicator from '../../../components/StepIndicator';
interface BasicLessonProps {
	lesson: Lesson;
	onComplete?: () => void;

}

const BasicLessonFlow: React.FC<BasicLessonProps> = ({ lesson, onComplete }) => {

	const { handleChat, systemMessageRef } = useLiveTutor();

	const [currentSlide, setCurrentSlide] = useState(0);
	const [currentExpressionIndex, setCurrentExpressionIndex] = useState(0);

	const totalSlides = 4;
	const steps = ['Key Words', 'Expressions', 'Conversation', 'Cultural'];

	const debouncedHandleChat = useCallback(
		debounce((message, add_to_log = true) => {
			handleChat(message, add_to_log);
		}, 3000),
		[handleChat]
	);

	const updateSystemMessage = (slide: number) => {
		let message = `You are a friendly tutor named Sarah. You are teaching English to beginners. 
	
They are learning about ${lesson.topic}. 

# INSTRUCTIONS:
- IMPORTANT: The exercise has just changed! Please go through the following new exercise with the user.
- Keep your responses VERY short and conversational.
- Don't ask complicated questions.
- Use encouraging words like "Good job!" or "Well done!".`;

		switch (slide) {
			case 0: // Previously case 1
				message += `# WHAT THE USER SEES:
- Key Words: ${lesson.key_words.join(', ')}
- Image description: ${lesson.key_words_image.alt}
- Objective: Describe the situation in the picture using the key words.`;
				break;
			case 1: // Previously case 2
				message += `# WHAT THE USER SEES:
- Key Expression: ${lesson.key_expressions[currentExpressionIndex].expression}
- Image description: ${lesson.key_expressions[currentExpressionIndex].image.alt}
- Objective: The user practices saying the sentence and understand what it means.`;
				break;
			case 2: // Previously case 3
				message += `
# THE USER SEE'S THE FOLLOWING CONVERSATION:
${lesson.conversation.map(d => d.role + ":" + d.content).join('\n')}

# ADDITIONAL INSTRUCTIONS:
- Practice lines in the conversation with the user.
- Pick a role and take turns reading the lines with the user.
- If the user says a line, then you can respond with just the next line.
- Don't start the conversation from the beginning each time. Just pick up where you left off.`;
				break;
			case 3: // Previously case 4
				message += `# NEW EXERCISE
- Discuss the following cultural question with the user.
- You can ask very BASIC questions to encourage the user to speak more.
- Don't ask too many questions though.
- Remember the user is a beginner.
            
# WHAT THE USER SEES:
- Cultural Question: ${lesson.cultural_question}
- Objective: Discuss the question in detail with the user. Encourage them to share their thoughts.`;
				break;
		}

		systemMessageRef.current = message;
		//debouncedHandleChat('Start off the exercise with the user.', false);

	};

	useEffect(() => {
		updateSystemMessage(currentSlide);
	}, [currentSlide, currentExpressionIndex]);


	const handleNext = () => {
		if (currentSlide < totalSlides - 1) {

			if (currentSlide === 1 && currentExpressionIndex < lesson.key_expressions.length - 1) {
				setCurrentExpressionIndex(currentExpressionIndex + 1);
			} else {
				const nextSlide = currentSlide + 1;
				setCurrentSlide(nextSlide);
				setCurrentExpressionIndex(0);

			}

		} else {
			console.log('Lesson completed!');
			onComplete?.();
		}
	};

	const handlePrevious = () => {
		if (currentSlide > 0) {
			if (currentSlide === 1 && currentExpressionIndex > 0) {
				setCurrentExpressionIndex(currentExpressionIndex - 1);
			} else {
				const prevSlide = currentSlide - 1;
				setCurrentSlide(prevSlide);
				setCurrentExpressionIndex(0);
			}
		}
	};


	const renderSlide = () => {

		switch (currentSlide) {

			case 0:
				return (
					<div className="flex flex-col items-center space-y-4 md:space-y-6 max-w-2xl mx-auto p-3 md:p-6 bg-gray-50 rounded-xl shadow-md">
						<h2 className="text-xl md:text-3xl font-bold text-gray-800 text-center">Picture Description: {lesson.topic}</h2>
						<div className="w-full space-y-4">
							<p className="text-lg text-gray-700">
								Look at the picture and describe what you see. Use the key words below to help you make sentences.
							</p>

						</div>
						<div className="w-full">
							<p className="text-lg font-semibold text-gray-700 mb-2">Key words:</p>
							<div className="flex flex-wrap gap-2">
								{lesson.key_words.map((word: string, index: number) => (
									<span
										key={index}
										className="bg-blue-100 text-blue-800 px-3 py-1 rounded-full text-sm font-medium hover:bg-blue-200 transition-colors duration-200"
									>
										{word}
									</span>
								))}
							</div>
						</div>
						<img
							src={lesson.key_words_image.src}
							alt={lesson.key_words_image.alt}
							className="w-full h-auto max-h-96 object-cover rounded-lg shadow-lg"
						/>


					</div>
				);
			case 1:
				return (
					<div className="flex flex-col items-center space-y-4 md:space-y-6 max-w-2xl mx-auto p-3 md:p-6 bg-gray-50 rounded-xl shadow-md">
						<h3 className="text-xl md:text-2xl font-bold text-gray-800 text-center">Key Expressions</h3>

						<p className="text-gray-600 text-center">
							Practice saying the following sentence:
						</p>

						<div className="bg-white p-3 md:p-6 rounded-lg shadow-sm w-full">
							<p className="text-lg md:text-xl font-medium text-gray-800 mb-4 text-center">
								{lesson.key_expressions[currentExpressionIndex].expression}
							</p>
							<img
								src={lesson.key_expressions[currentExpressionIndex].image.src}
								alt={lesson.key_expressions[currentExpressionIndex].image.alt}
								className="w-full h-48 md:h-80 object-cover rounded-lg shadow-md"
							/>
						</div>



						<p className="text-sm text-gray-500 mt-4 text-center">
							Progress: {currentExpressionIndex + 1} / {lesson.key_expressions.length}
						</p>
					</div>
				);
			case 2: // Conversation
				return (
					<div className="flex flex-col space-y-4 max-w-2xl mx-auto p-2 md:p-0">
						<h3 className="text-lg md:text-xl font-semibold text-center mb-2 md:mb-4">Conversation Practice</h3>
						<p className="hidden md:show text-center text-lg text-gray-600 border-b-2 pb-4 mb-4
						">Practice the following conversation with your tutor:</p>
						<div className="space-y-3 md:space-y-4">
							{lesson.conversation.map((dialogue: any, index: number) => (
								<div
									key={index}
									className={`flex ${index % 2 === 0 ? 'justify-start' : 'justify-end'}`}
								>
									<div className={`flex ${index % 2 === 0 ? 'flex-row' : 'flex-row-reverse'} max-w-[90%] md:max-w-[80%]`}>
										<div className={`w-8 h-8 md:w-10 md:h-10 rounded-full flex items-center justify-center text-white font-bold ${index % 2 === 0 ? 'bg-blue-500 mr-2 md:mr-3' : 'bg-green-500 ml-2 md:ml-3'
											}`}>
											{dialogue.role.charAt(0).toUpperCase()}
										</div>
										<div className={`p-2 md:p-3 rounded-lg text-sm md:text-base ${index % 2 === 0 ? 'bg-blue-100 text-blue-800' : 'bg-green-100 text-green-800'
											}`}>
											<p className="font-semibold mb-1">{dialogue.role.charAt(0).toUpperCase() + dialogue.role.slice(1)}</p>
											<p>{dialogue.content}</p>
											{dialogue.audio && (
												<button className="mt-2 text-blue-500 hover:text-blue-700 transition-colors">
													<MdReplay className="inline mr-1" /> Play Audio
												</button>
											)}
										</div>
									</div>
								</div>
							))}
						</div>
					</div>
				);

			case 3: // Cultural Question
				return (
					<div className="flex flex-col items-center p-2 md:p-0">
						<h3 className="text-lg md:text-xl font-semibold mb-3 text-center">Share Your Thoughts</h3>

						<p className="text-center text-base md:text-lg font-semibold mt-6 md:mt-12 px-4">{lesson.cultural_question}</p>
						<img
							src={lesson.lesson_image.src}
							alt={lesson.lesson_image.alt}
							className="w-48 h-48 md:w-64 md:h-64 object-cover rounded-lg shadow-md my-6"
						/>



					</div>
				);

			default:
				return null;
		}
	};

	return (
		<div className="max-w-2xl mx-auto p-3 md:p-6 mb-20 md:mb-0">
			<StepIndicator steps={steps} currentSlide={currentSlide} setCurrentSlide={setCurrentSlide} />
			<div className="bg-white rounded-lg shadow-lg p-3 md:p-6">
				{renderSlide()}
			</div>
			<NavigationArrows steps={steps} currentSlide={currentSlide} disabled={currentSlide === 0} handleNext={handleNext} handlePrevious={handlePrevious}
				progressString={currentSlide === 2
					? `${currentExpressionIndex + 1}/${lesson.key_expressions.length}`
					: `${currentSlide + 1}/${totalSlides}`}
			/>
		</div>
	);
};

export default BasicLessonFlow;