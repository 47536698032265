import React from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import LinearProgressWithLabel from './LinearProgressWithLabel';

interface ScoresProps {
	pronScore: number;
	accuracyScore: number;
	fluencyScore: number;
	prosodyScore: number;
	compScore: number;
}

const Results: React.FC<{ scores: ScoresProps }> = ({ scores }) => {

	return (
		<div className="p-4">
			<div className="grid grid-cols-1 md:grid-cols-12 gap-4">
				{/* Circular progress bar */}
				<div className="md:col-span-4 text-center mb-6">
					<h2 className="font-bold text-xl mb-4">Pronunciation Score</h2>
					<div className="max-w-[200px] mx-auto">
						<CircularProgressbar
							value={scores.pronScore}
							text={`${scores.pronScore}%`}
							styles={{
								path: {
									stroke: `${scores.pronScore < 60
										? 'red'
										: scores.pronScore < 80
											? 'orange'
											: 'green'
										}`,
								},
								text: {
									fill: 'black',
									fontSize: '16px',
								},
							}}
						/>
					</div>
				</div>

				{/* Linear progress bars */}
				<div className="md:col-span-8">
					<div className="flex flex-col gap-6">
						<div>
							<LinearProgressWithLabel
								label="Accuracy Score"
								value={scores.accuracyScore}
							/>
						</div>
						<div>
							<LinearProgressWithLabel
								label="Fluency Score"
								value={scores.fluencyScore}
							/>
						</div>
						{"prosodyScore" in scores &&
							(
								<div>
									<LinearProgressWithLabel
										label="Prosody Score"
										value={scores.prosodyScore}
									/>
								</div>
							)
						}
						<div>
							<LinearProgressWithLabel
								label="Completeness Score"
								value={scores.compScore}
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Results;