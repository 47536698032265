class AudioRecorder {
  private mediaStream: MediaStream | null = null;
  private mediaRecorder: MediaRecorder | null = null;
  private audioChunks: Blob[] = [];
  private timeoutId: NodeJS.Timeout | null = null;
  private readonly TIME_LIMIT = 30000;

  public async init(): Promise<void> {
    console.log("Initializing audio recorder??");
    this.mediaStream = await navigator.mediaDevices.getUserMedia({
      audio: true,
    });
    this.resetRecorder();
  }

  private resetRecorder(): void {
    if (this.mediaStream) {
      this.mediaRecorder = new MediaRecorder(this.mediaStream, {
        mimeType: "audio/webm",
      });

      this.mediaRecorder.ondataavailable = (event: BlobEvent) => {
        if (event.data.size > 0) {
          this.audioChunks.push(event.data);
        }
      };
    }
  }

  public clear(): void {
    this.audioChunks = [];
  }

  public start(): void {
    if (!this.mediaRecorder) {
      throw new Error("Media recorder is not initialized.");
    }
    this.audioChunks = []; // Clear previous chunks
    this.mediaRecorder.start();
  }

  public async stop(): Promise<Blob | null> {
    console.log("Stopping media recorder");
    if (!this.isRecording()) {
      const blob = new Blob(this.audioChunks, { type: "audio/wav" });
      return blob;
    }

    return new Promise((resolve, reject) => {
      if (!this.mediaRecorder) {
        reject(new Error("Media recorder is not initialized."));
        return;
      }

      this.mediaRecorder.onstop = () => {
        const blob = new Blob(this.audioChunks, { type: "audio/wav" });
        //this.resetRecorder(); // Reset the recorder for next use
        resolve(blob);
      };

      console.log("Stopping media recorder");

      this.mediaRecorder.stop();
    });
  }

  public isRecording(): boolean {
    return this.mediaRecorder?.state === "recording";
  }
}

export { AudioRecorder };
