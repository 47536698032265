import React, { useState, useCallback, useEffect, useRef } from 'react';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import { MdReplay } from 'react-icons/md';
import debounce from 'lodash/debounce';
import { useLiveTutor } from '../../../hooks/LiveTutorProvider';
import RoleplayConversation from './speaking/RoleplayConversation';
import PronunciationPracticePage from './Pronunciation';
import FillInTheBlanks from './vocabulary/FillInTheBlanks';
import { Dialogue } from '../../../types';
import { useStorage } from '../../../hooks/useStorage';
import { useLoading } from '../../../hooks/LoadingProvider';
import { useServer } from '../../../hooks/useServer';
import { useToast } from '../../../hooks/ToastProvider';
import NavigationArrows from '../../../components/NavigationArrows';
import StepIndicator from '../../../components/StepIndicator';

interface BasicInterestFlowProps {
	voice_id: string;
	words: any[];
	exercise: any;
	conversation: Dialogue[];
	questions: any[];
	onComplete?: () => void;
}

const BasicInterestFlow: React.FC<BasicInterestFlowProps> = ({
	voice_id,
	words,
	exercise,
	conversation,
	questions,
	onComplete
}) => {
	const { showToast } = useToast();
	const { saveBlobToStorage, loadBlobFromStorage, deleteFromStorage, loadFromStorage, saveToStorage } = useStorage();
	const { showLoading, hideLoading } = useLoading();
	const { postPronunciationAssessmentResults } = useServer();
	const { handleChat, systemMessageRef } = useLiveTutor();
	const [currentSlide, setCurrentSlide] = useState(0);

	const [currentPronunciationIndex, setCurrentPronunciationIndex] = useState(0);
	const resultsRef = useRef<any[]>([]);

	const [results, setResults] = useState<any[]>([]);

	const steps = [
		'Pronunciation',
		'Fill in Blanks',
		'Conversation',
		'Questions'
	];


	const debouncedHandleChat = useCallback(
		debounce((message, add_to_log = true) => {
			handleChat(message, add_to_log);
		}, 3000),
		[handleChat]
	);

	const updateSystemMessage = (slide: number) => {
		let message = `You are a friendly tutor named Sarah. You are teaching English to beginners.

# INSTRUCTIONS:
- The exercise has just changed! Please guide the user through the new exercise.
- Keep your responses VERY short and conversational.`;

		switch (slide) {
			case 0:
				message += `\n# CURRENT EXERCISE: Pronunciation Practice
- Help the user practice pronouncing the words correctly
- Give feedback on their pronunciation
- Current Word: ${words[currentPronunciationIndex]?.word}`;
				break;
			case 1:

				const answersList = Object.values(exercise.Answers) as string[];
				const sentenceList = Object.values(exercise.Sentences) as string[];

				message += `\n# CURRENT EXERCISE: Fill in the Blanks
- Guide the user to complete the sentences

# Exercise Content:
${sentenceList.map((sentence: string, index: number) => `- Sentence ${index + 1}: ${sentence}`).join('\n')}
${answersList.map((answer: string, index: number) => `- Answer ${index + 1}: ${answer}`).join('\n')}

# Instructions:
- Help the user when they ask for it
- Instead of _____ (five underscores), say "blank"
- Keep your responses short and conversational`;
				break;
			case 2:
				message += `\n# CURRENT EXERCISE: Conversation Practice
- Practice the dialogue with the user
- The user is given the following dialogue:
${conversation.map((dialogue) => `${dialogue.role}: ${dialogue.content || dialogue.line}`).join('\n')}
- The user can record their responses and we see if they made any mistakes.`;
				break;
			case 3:
				message += `\n# CURRENT EXERCISE: Comprehension Questions
- Help the user understand and answer the questions
- Encourage detailed responses

# Questions:
${questions.map((question, index) => `- Question ${index + 1}: ${question.question}`).join('\n')}
`;
				break;
		}

		systemMessageRef.current = message;
		debouncedHandleChat('Start off the exercise with the user.', false);
	};

	useEffect(() => {
		updateSystemMessage(currentSlide);
	}, [currentSlide]);

	const handleNext = () => {
		if (currentSlide < steps.length - 1) {
			if (currentSlide === 0) {
				if (currentPronunciationIndex < words.length - 1) {
					setCurrentPronunciationIndex(currentPronunciationIndex + 1);
				} else {
					setCurrentSlide(currentSlide + 1);
				}
			} else {
				setCurrentSlide(currentSlide + 1);
			}
		} else {
			console.log('Lesson completed!');
			submitPronunciationAssessment();

		}
	};

	const handlePrevious = () => {
		if (currentSlide === 0 && currentPronunciationIndex > 0) {
			setCurrentPronunciationIndex(currentPronunciationIndex - 1);
		} else if (currentSlide > 0) {
			setCurrentSlide(currentSlide - 1);
		}
	};

	const submitPronunciationAssessment = async () => {
		showLoading("Submitting results...");

		const audioPromises = resultsRef.current.map(
			(result) => result && loadBlobFromStorage(result.audio)
		);

		// Wait for all audio blobs to load
		Promise.all(audioPromises)
			.then(async (audioBlobs) => {
				// if assessment
				await postPronunciationAssessmentResults(
					resultsRef.current,
					words.map((word) => word.word),
					"session1",
					audioBlobs,
				);

				// Delete all audio blobs
				await Promise.all(
					resultsRef.current.map(
						(result) =>
							result && deleteFromStorage(result.audio)
					)
				);

				onComplete?.();
			})
			.catch((error) => {

				showToast("Error submitting results");
				console.error("Error loading audio blobs", error);
			}).finally(() => {
				hideLoading();
			});
	};


	const renderSlide = () => {
		switch (currentSlide) {
			case 0:
				return (
					<div className=" bg-white rounded-lg shadow-lg p-6">
						<div className="space-y-6">
							<PronunciationPracticePage
								pronunciationProgress={{
									name: "Practice Session",
									sessionId: "session1",
									speechElements: words,
									isAssessment: false,
									isFrequency: false,
									role: "student",
									interest: "general"
								}}
								onComplete={() => handleNext()}
								currentPronunciationIndex={currentPronunciationIndex}
								resultsRef={resultsRef}
							/>
						</div>
					</div>
				);
			case 1:
				const answersList = Object.values(exercise.Answers) as string[];
				const sentenceList = Object.values(exercise.Sentences) as string[];

				return (
					<div className=" bg-white rounded-lg shadow-lg p-6">
						<div className="space-y-6">
							<h2 className="text-2xl font-bold text-center">Fill in the Blanks</h2>
							<FillInTheBlanks wordBank={exercise.Words} sentences={sentenceList} answers={answersList} />
						</div>
					</div>
				);
			case 2:


				return (
					<div className="space-y-6">

						<RoleplayConversation
							voice_id={voice_id}
							dialogue={conversation}
							setResults={setResults}
						/>
					</div>
				);
			case 3:
				return (
					<div className=" bg-white rounded-lg shadow-lg p-6">
						<div className="space-y-6">
							<h2 className="text-2xl font-bold text-center">Questions</h2>
							<div className="space-y-4">
								{questions.map((question, index) => (
									<div key={index} className="bg-white p-4 rounded-lg shadow">
										<p className="font-medium text-lg mb-2">{question.question}</p>

									</div>
								))}
							</div>
						</div>
					</div>
				);
			default:
				return null;
		}
	};


	return (
		<div className="max-w-2xl mx-auto md:p-6 mb-16 md:mb-0">
			<StepIndicator
				steps={steps}
				currentSlide={currentSlide}
				setCurrentSlide={setCurrentSlide}
			/>


			{renderSlide()}

			<NavigationArrows
				steps={steps}
				currentSlide={currentSlide}
				disabled={currentSlide === 0 && currentPronunciationIndex === 0}
				handleNext={handleNext}
				handlePrevious={handlePrevious}
			/>
		</div>
	);
};

export default BasicInterestFlow;