import React, { useState } from 'react';
import { useLiveTutor } from '../../../../hooks/LiveTutorProvider';
import { FaCheck, FaTimes } from 'react-icons/fa'; // Add this import

interface VocabularyProps {
	wordBank: string[];
	sentences: string[];
	answers: string[];
}

const Vocabulary: React.FC<VocabularyProps> = ({ wordBank, sentences, answers }) => {
	const { handleChat, systemMessageRef } = useLiveTutor();
	const [userAnswers, setUserAnswers] = useState<string[]>(Array(answers.length).fill(''));
	const [showResults, setShowResults] = useState(false); // Add this state

	const handleSelectChange = (index: number, value: string) => {
		const newUserAnswers = [...userAnswers];
		newUserAnswers[index] = value;
		setUserAnswers(newUserAnswers);
		setShowResults(false); // Reset results when answer changes
	};

	const checkAnswers = () => {
		setShowResults(true); // Show results when checking answers
		const isCorrect = answers.every((answer, index) => answer === userAnswers[index]);

		systemMessageRef.current = `You are a friendly tutor named Sarah. You are teaching English to beginners.

# INSTRUCTIONS:
- The exercise has just changed! Please guide the user through the new exercise.
- Keep your responses VERY short and conversational.
- Remember it's important to be encouraging and positive.
- You are talking to an English beginner so use simple vocabulary and grammar and short sentences.

# CURRENT EXERCISE: Fill in the Blanks
- Guide the user to complete the sentences

# Exercise Content:
${sentences.map((sentence, index) => `- Sentence ${index + 1}: ${sentence}`).join('\n')}

# User Answers:
${userAnswers.map((answer, index) => `- Answer ${index + 1}: ${answer}`).join('\n')}

# Correct Answers:
${answers.map((answer, index) => `- Answer ${index + 1}: ${answer}`).join('\n')}

- Don't bombard the user with too much information at once. Give them time to process and respond.
- Go one step at a time and ask the user if they have any questions.

${isCorrect ? 'The answers are correct!' : 'The answers are incorrect. Please try again.'}
`;

		handleChat('How did I do?', false);
	};

	return (
		<div className="bg-gray-100 p-4">
			<div className="max-w-4xl mx-auto">
				<header className="bg-white shadow-sm mb-4 rounded-lg">
					<div className="px-4 py-5">
						<h1 className="text-xl font-semibold text-gray-800">Vocabulary Practice</h1>
					</div>
				</header>

				<div className="bg-white rounded-lg shadow-md p-6">
					{/* Sentences with Dropdowns */}
					<div className="space-y-6">
						{sentences.map((sentence, index) => (
							<div key={index} className="flex flex-col sm:flex-row sm:items-center gap-2">
								<div className="flex-1 flex items-center gap-3">
									<span>{sentence}</span>
									{showResults && (
										userAnswers[index] === answers[index] ? (
											<FaCheck className="text-green-500 text-xl" />
										) : (
											<FaTimes className="text-red-500 text-xl" />
										)
									)}
								</div>
								<select
									value={userAnswers[index]}
									onChange={(e) => handleSelectChange(index, e.target.value)}
									className={`w-full sm:w-[200px] h-10 border-2 rounded-md px-2
                                        focus:outline-none focus:ring-2 focus:ring-offset-2
                                        ${showResults
											? userAnswers[index] === answers[index]
												? 'border-green-500 focus:border-green-500 focus:ring-green-500'
												: 'border-red-500 focus:border-red-500 focus:ring-red-500'
											: 'border-gray-300 focus:border-blue-500 focus:ring-blue-500'
										}`}
								>
									<option value="">Select a word</option>
									{wordBank.map((word, wordIndex) => (
										<option key={wordIndex} value={word}>
											{word}
										</option>
									))}
								</select>
							</div>
						))}
					</div>

					{/* Submit Button */}
					<button
						onClick={checkAnswers}
						className="w-full mt-6 bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700 
                            focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-colors duration-200"
					>
						Check Answers
					</button>
				</div>
			</div>
		</div>
	);
};

export default Vocabulary;