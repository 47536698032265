import React, { forwardRef } from 'react';

interface TestimonialProps { }

const testimonials = [
	{
		id: 1,
		name: "Sarah Johnson",
		role: "Marketing Manager",
		quote: "This platform has significantly improved my professional English. The real-time feedback is invaluable!",
		image: "/api/placeholder/150/150"
	},
	{
		id: 2,
		name: "Michael Chen",
		role: "Software Developer",
		quote: "The CLB integration helped me showcase my English skills to potential employers. Highly recommended!",
		image: "/api/placeholder/150/150"
	},
	{
		id: 3,
		name: "Emily Rodriguez",
		role: "HR Specialist",
		quote: "The professional scenario simulations boosted my confidence in job interviews. It's been a game-changer!",
		image: "/api/placeholder/150/150"
	}
];

const TestimonialCard = ({ testimonial }: { testimonial: typeof testimonials[0] }) => (
	<div className="bg-white rounded-lg shadow-xl p-6 md:p-8">
		<div className="flex items-center mb-6">
			<img
				src={testimonial.image}
				alt={testimonial.name}
				className="w-16 h-16 md:w-20 md:h-20 rounded-full mr-4"
			/>
			<div>
				<h3 className="text-lg md:text-xl font-semibold text-blue-800">
					{testimonial.name}
				</h3>
				<p className="text-sm md:text-base text-blue-600">{testimonial.role}</p>
			</div>
		</div>
		<p className="text-sm md:text-base text-gray-700 italic">"{testimonial.quote}"</p>
	</div>
);

const Testimonials = forwardRef<HTMLDivElement, TestimonialProps>((props, ref) => {
	return (
		<div className="bg-blue-50 py-12 md:py-16" ref={ref}>
			<div className="max-w-6xl mx-auto px-4">
				<h2 className="text-2xl md:text-3xl font-bold text-center text-blue-800 mb-8 md:mb-10">
					What Our Users Say
				</h2>

				{/* Mobile View - Vertical Stack */}
				<div className="md:hidden space-y-6">
					{testimonials.map((testimonial) => (
						<div key={testimonial.id}>
							<TestimonialCard testimonial={testimonial} />
						</div>
					))}
				</div>

				{/* Desktop View - Scrolling Animation */}
				<div className="hidden md:block relative overflow-hidden">
					<div className="animate-scroll flex">
						{/* Original testimonials */}
						{testimonials.map((testimonial) => (
							<div key={testimonial.id} className="w-96 flex-shrink-0 px-4">
								<TestimonialCard testimonial={testimonial} />
							</div>
						))}
						{/* Duplicated testimonials for seamless loop */}
						{testimonials.map((testimonial) => (
							<div key={`${testimonial.id}-clone`} className="w-96 flex-shrink-0 px-4">
								<TestimonialCard testimonial={testimonial} />
							</div>
						))}
					</div>
				</div>
			</div>
		</div>
	);
});

export default Testimonials;