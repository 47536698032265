import React, { useState, useRef, useEffect, forwardRef } from 'react';
import Typed from 'typed.js';
import { useServer } from '../hooks/useServer';
import { getISO_639_1, getLanguage } from "language-flag-colors";

interface PProps {
	children: string;
	className?: string;
	onClick?: () => void;
}

const P = forwardRef<HTMLParagraphElement, PProps>(({
	children,
	className,
	onClick
}, ref) => {
	const { translate, user } = useServer();
	const [isTranslated, setIsTranslated] = useState(false);
	const [displayText, setDisplayText] = useState(children);
	const typedRef = useRef<Typed | null>(null);
	const innerRef = useRef<HTMLParagraphElement>(null);

	// Merge the forwarded ref with our internal ref
	const setRefs = (element: HTMLParagraphElement) => {
		// Update the internal ref
		(innerRef as React.MutableRefObject<HTMLParagraphElement | null>).current = element;


		// Update the forwarded ref
		if (ref) {
			if (typeof ref === 'function') {
				ref(element);
			} else {
				(ref as React.MutableRefObject<HTMLParagraphElement | null>).current = element;
			}
		}
	};

	useEffect(() => {
		if (innerRef.current) {
			typedRef.current = new Typed(innerRef.current, {
				strings: [displayText],
				typeSpeed: 10,
				showCursor: false,
			});
		}

		return () => {
			if (typedRef.current) {
				typedRef.current.destroy();
			}
		};
	}, [displayText]);

	const handleTranslate = async () => {
		if (!isTranslated) {
			const languageCode = getISO_639_1(user.nativeLanguage);
			const { text } = await translate(children, 'auto', languageCode || 'en');
			setDisplayText(text);
			setIsTranslated(true);
		} else {
			setDisplayText(children);
			setIsTranslated(false);
		}
	};

	return (
		<p
			ref={setRefs}
			onClick={() => {
				onClick && onClick();
				handleTranslate();
			}}
			style={{ cursor: 'pointer' }}
			title={isTranslated ? 'Click to show original' : 'Click to translate'}
			className={className}
		/>
	);
});

P.displayName = 'P';

export default P;