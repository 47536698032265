import { useNavigate } from 'react-router-dom';
import {
	MdVolumeUp,
	MdVolumeMute,
	MdMic,
	MdStop,
	MdArrowBack,
	MdHelpOutline,
	MdPause,
	MdSettings,
	MdTextFields,
	MdBook,
	MdImage
} from 'react-icons/md';
import {
	IoVolumeHigh,
	IoVolumeLow,
	IoStop,
	IoHelpCircleOutline,
	IoHeadset,
	IoArrowBack,
} from "react-icons/io5";

import { useTimer } from "react-timer-hook";

import styles from "./Pronunciation.module.css";
import { Fragment, useEffect, useRef, useState, useCallback, createRef } from "react";

import { PronunciationProgress, RecognitionCallbacks, defaultPronunciationSettings } from "../../../types";

import { motion, AnimatePresence } from "framer-motion";

import { useAzure } from "../../../hooks/useAzure";
import _ from "lodash";
import Results from "../../../components/pronunciation/Results";


import {
	useStorage,
} from "../../../hooks/useStorage";

import { useServer } from "../../../hooks/useServer";
import { useLoading } from "../../../hooks/LoadingProvider";
import PronunciationSettings from "../../../pages/pronunciation/PronunciationSettings";

import { useToast } from "../../../hooks/ToastProvider";
import WordList from "../../../components/pronunciation/WordList";


const containerVariants = {
	hidden: {
		opacity: 0,
		scaleX: 0,
		transformOrigin: "left"
	},
	visible: {
		opacity: 1,
		scaleX: 1,
		transition: {
			type: "spring",
			damping: 20,
			stiffness: 100
		}
	},
	exit: {
		opacity: 0,
		scaleX: 0,
		transition: {
			duration: 0.2
		}
	}
};

interface PronunciationPracticePageProps {
	pronunciationProgress: PronunciationProgress;
	onComplete: () => void;
	currentPronunciationIndex: number;
	resultsRef: any;
}

const PronunciationPracticePage: React.FC<PronunciationPracticePageProps> = ({ pronunciationProgress, onComplete, currentPronunciationIndex, resultsRef }) => {
	const {
		auth,
		ensureToken,
		startPronunciationAssessment,
		speakTextAsync,
		stopPronunciationAssessment,
		stopTextAsync
	} = useAzure();

	const { saveBlobToStorage, loadBlobFromStorage, deleteFromStorage, loadFromStorage, saveToStorage } = useStorage();
	const [showVideoIntro, setShowVideoIntro] = useState(!!pronunciationProgress.video);

	const { useLocalStorage } = useStorage();

	const [showSentence, setShowSentence] = useState(false);
	const [showDefinition, setShowDefinition] = useState(false);

	const [imageState, setImageState] = useState<{
		isVisible: boolean;
		isLoading: boolean;
		url?: string;
		sentence?: string;
	}>({
		isVisible: false,
		isLoading: false,
	});


	const { getGlobalAverage, postPronunciationAssessmentResults, generateImage, getUserProfile, getPronunciationContent } = useServer();
	const { showLoading, hideLoading, isLoading } = useLoading();
	const [showSettings, setShowSettings] = useState(false);

	const [settings, setSettings] = useLocalStorage("pronunciationSettings", defaultPronunciationSettings);
	const settingsRef = useRef(settings);

	const stateRef = useRef<PronunciationProgress>(pronunciationProgress);


	const currentRef = useRef(currentPronunciationIndex);

	const [isRecognizing, setIsRecognizing] = useState(false);
	const isRecognizingRef = useRef(isRecognizing);

	const [isPlayingSlow, setIsPlayingSlow] = useState(false);
	const [isPlayingFast, setIsPlayingFast] = useState(false);
	const [displayText, setDisplayText] = useState("");

	const [percentile, setPercentile] = useState<number | null>(null);
	const [isPercentileLoading, setIsPercentileLoading] = useState(false);
	const audioRef = createRef<HTMLAudioElement>();

	const [audioPlaying, setAudioPlaying] = useState(false);

	const textareaRef = useRef<any>(null);

	useEffect(() => {
		return () => {
			stopPronunciationAssessment();
		}
	}, []);


	useEffect(() => {
		currentRef.current = currentPronunciationIndex;

		(async () => {
			if (
				currentRef.current < stateRef.current.speechElements.length
			) {
				if (textareaRef.current) {
					const element = textareaRef.current;
					element.classList.add(styles.successAnimation);

					// Remove the animation class after 1 second
					setTimeout(() => {
						element.classList.remove(styles.successAnimation);
					}, 2000);
				}

				if (isRecognizingRef.current && settingsRef.current.autoStart) {
					await stopPronunciationAssessment();
					await startRecognition();
				} else {
					setIsRecognizing(false);
				}
			} else {
				console.log("Finished");
				setIsRecognizing(false);
			}
		})();
	}, [currentPronunciationIndex]);


	useEffect(() => {
		settingsRef.current = settings;
	}, [settings]);

	useEffect(() => {
		(async () => {

			if (resultsRef.current[currentRef.current]?.percentile) {
				setPercentile(resultsRef.current[currentRef.current].percentile);
			} else if (resultsRef.current[currentRef.current]) {
				setIsPercentileLoading(true);
				const percentileData = await getGlobalAverage(stateRef.current.speechElements[currentPronunciationIndex].word);

				const currentScore = resultsRef.current[currentRef.current].scores.pronScore;
				let percentileValue;
				if (currentScore >= percentileData.score['99_percentile']) {
					percentileValue = 99;
				} else if (currentScore >= percentileData.score['90_percentile']) {
					percentileValue = 90;
				} else if (currentScore >= percentileData.score['75_percentile']) {
					percentileValue = 75;
				} else if (currentScore >= percentileData.score['50_percentile']) {
					percentileValue = 50;
				} else {
					percentileValue = 0;
				}

				setPercentile(percentileValue);
				resultsRef.current[currentRef.current].percentile = percentileValue;
				setIsPercentileLoading(false);
			} else {
				setPercentile(null);
			}
		})();
	}, [currentPronunciationIndex, resultsRef.current[currentRef.current]]);

	useEffect(() => {

		if (!auth) {
			console.log("No auth token. Redirecting to login.");
			ensureToken();
			showLoading("Loading...");

		} else {
			console.log("Auth token found.");
			hideLoading();
		}
	}, [auth]);

	const startRecognition = async () => {

		setDisplayText("Listening...");

		const callbacks = {} as RecognitionCallbacks;

		callbacks.recognizing = (s: any, e: any) => {
			setDisplayText(`${e.result.text}`);
		};

		callbacks.results = async (words: any, scores: any, blob: Blob) => {
			const audiofileName = `${stateRef.current.sessionId}-${currentRef.current}`;

			await saveBlobToStorage(audiofileName, blob);

			// set the blob to a audioUrl
			const blobUrl = URL.createObjectURL(blob);

			const newResults = {
				words,
				scores,
				timestamp: new Date().toISOString(),
				audio: audiofileName,
				blobUrl: blobUrl,
			};

			resultsRef.current[currentRef.current] = newResults;
			setIsRecognizing(false);
		}
		startPronunciationAssessment(
			stateRef.current.speechElements[currentPronunciationIndex]["word"],
			callbacks
		)
	};

	useEffect(() => {
		console.log("isRecognizing", isRecognizing);
		isRecognizingRef.current = isRecognizing;

		const handleRecognitionChange = async () => {
			if (isRecognizing) {

				await startRecognition();
			} else {
				setDisplayText("Paused.");
				await stopPronunciationAssessment();
			}
		};

		handleRecognitionChange();
	}, [isRecognizing]);



	const handleGenerateImage = async () => {
		const currentSentence = stateRef.current.speechElements[currentPronunciationIndex].sentence;

		// If same sentence, just toggle visibility
		if (currentSentence === imageState.sentence) {
			setImageState(prev => ({ ...prev, isVisible: !prev.isVisible }));
			return;
		}

		// Generate new image
		setImageState(prev => ({ ...prev, isLoading: true, isVisible: true }));

		const signedUrl = await generateImage(currentSentence);
		stateRef.current.speechElements[currentPronunciationIndex].imageUrl = signedUrl;

		setImageState({
			isVisible: true,
			isLoading: false,
			url: signedUrl,
			sentence: currentSentence,
		});
	};

	if (!stateRef.current.speechElements[currentPronunciationIndex]) {
		return (
			<div className="min-h-screen">
				<div className="h-full p-4">
					<div className="flex flex-col items-center justify-center h-full">
						<div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
						<p className="mt-4">Loading...</p>
					</div>
				</div>
			</div>
		);
	}

	if (showVideoIntro && pronunciationProgress.video) {


		return (<div className="flex flex-col pb-32">
			<div className="fixed inset-0 bg-black bg-opacity-95 z-50 flex items-center justify-center">
				<div className="max-w-3xl w-full mx-4 bg-white rounded-lg overflow-hidden shadow-2xl">
					<div className="p-6">
						<h2 className="text-2xl font-bold mb-4">Phoneme Practice: {pronunciationProgress.metric}</h2>

						<div className="aspect-w-16 aspect-h-9 mb-6">
							<video
								className="w-full rounded-lg"
								controls
								autoPlay
								src={pronunciationProgress.video}
							>
								Your browser does not support the video tag.
							</video>
						</div>

						<div className="bg-blue-50 p-4 rounded-lg mb-6">
							<p className="text-blue-800">
								Repeat the (<strong>{pronunciationProgress.metric}</strong>) sound after the video.
							</p>
						</div>

						<div className="flex justify-center">
							<button
								onClick={() => setShowVideoIntro(false)}
								className="bg-blue-600 hover:bg-blue-700 text-white px-8 py-3 rounded-lg font-semibold 
                     transition-colors duration-200 flex items-center gap-2"
							>
								Start Practice
								<svg
									className="w-5 h-5"
									fill="none"
									stroke="currentColor"
									viewBox="0 0 24 24"
								>
									<path
										strokeLinecap="round"
										strokeLinejoin="round"
										strokeWidth={2}
										d="M14 5l7 7m0 0l-7 7m7-7H3"
									/>
								</svg>
							</button>
						</div>
					</div>
				</div>
			</div>

		</div>

		)

	}

	const message = {
		99: "Excellent work! 🏆",
		90: "Keep up the excellent work! 🎯",
		75: "Keep up the good work! 🎉",
		50: "Good effort! 🚀",
		0: "Keep practicing! 🌱",
	} as any;


	return (
		<div className="flex flex-col">
			<main className={`flex-1 p-4 ${isLoading ? "opacity-50 blur-sm" : ""}`}>
				<div className="flex flex-col justify-between max-w-xl mx-auto">
					{/* Show either results or main content */}
					{isPercentileLoading ? (
						<div className="flex items-center justify-center">
							<div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600" />
						</div>
					) : (percentile !== null) && resultsRef.current[currentRef.current] ? (
						// Results Overlay
						<div className="flex-grow flex flex-col justify-center text-center">
							<div className="mb-6">

								<WordList words={resultsRef.current[currentRef.current].words} />
								<div className="flex justify-center gap-4 my-4">
									<button
										disabled={!resultsRef.current[currentRef.current].blobUrl}
										onClick={() => {
											if (audioPlaying) {
												audioRef.current!.pause();
												audioRef.current!.currentTime = 0;
												setAudioPlaying(false);
												return;
											}
											setAudioPlaying(true);
											audioRef.current?.play();
										}}
										className="p-3 rounded-full bg-gray-100 hover:bg-gray-200 disabled:opacity-50"
									>
										{audioPlaying ? <IoStop /> : <IoHeadset />}
									</button>
									<button
										onClick={() => {
											if (isPlayingSlow) {
												console.log("stopping slow");
												stopTextAsync();
												setIsPlayingSlow(false);
												return;
											}
											setIsPlayingSlow(true);

											speakTextAsync({
												text: stateRef.current.speechElements[currentPronunciationIndex]["word"],
												rate: -100,
												cb: () => setIsPlayingSlow(false),
											});
										}}
										className="p-3 rounded-full bg-gray-100 hover:bg-gray-200"
									>
										{isPlayingSlow ? <IoStop /> : <IoVolumeLow />}
									</button>
									<button
										onClick={() => {
											if (isPlayingFast) {
												stopTextAsync();
												setIsPlayingFast(false);
												return;
											}
											setIsPlayingFast(true);
											speakTextAsync({
												text: stateRef.current.speechElements[currentPronunciationIndex]["word"],
												cb: () => setIsPlayingFast(false),
											});
										}}
										className="p-3 rounded-full bg-gray-100 hover:bg-gray-200"
									>
										{isPlayingFast ? <IoStop /> : <IoVolumeHigh />}
									</button>
								</div>
								<Results scores={resultsRef.current[currentRef.current].scores} />

								{/* Percentile Section with Loading State */}
								<div className="mt-6 flex flex-col items-center">
									<div className="relative">
										<div className="absolute -inset-1.5 bg-gradient-to-r from-blue-500 to-purple-600 rounded-lg blur opacity-30"></div>
										<div className="relative px-8 py-4 bg-white rounded-lg shadow-xl border border-gray-100">
											<div className="flex flex-col items-center gap-2">
												{percentile > 0 ? (
													<>
														<div className="text-2xl font-bold bg-gradient-to-r from-blue-600 to-purple-600 bg-clip-text text-transparent">
															Better pronunciation than
														</div>
														<div className="flex items-baseline gap-1">
															<span className="text-4xl font-extrabold text-blue-600">
																{percentile}%
															</span>
															<span className="text-lg text-gray-700 font-medium">
																of all speakers
															</span>
														</div>
													</>
												) : null}
												<div className="text-sm text-gray-500 mt-1">
													{message[percentile]}
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>

							{/* Retry Button */}
							<button
								onClick={() => {
									onComplete();
									// Reset any other necessary states
								}}
								className="mt-6 px-6 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
							>
								Next
							</button>
							<button
								onClick={() => {
									setPercentile(null);
									// Reset any other necessary states
								}}
								className="mt-6 px-6 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
							>
								Try Again
							</button>
						</div>
					) : (
						// Main Content (Word + Buttons)
						<div className="flex-grow flex flex-col justify-center text-center">
							<p className="text-xl mb-4">Pronounce this word:</p>
							<div className="w-full md:w-4/5 relative mx-auto">
								<textarea
									value={stateRef.current.speechElements[currentPronunciationIndex].word}
									readOnly
									className="w-full p-6 bg-gray-100 rounded-lg  resize-none min-h-[120px] text-center text-2xl  shadow-inner "
									ref={textareaRef}
								/>


								{/* Playback Buttons */}
								<div>
									<div className="absolute bottom-3 left-0 translate-y-1/2 flex gap-2">
										<button
											onClick={() => {
												if (isPlayingSlow) return;
												setIsPlayingSlow(true);
												speakTextAsync({
													text: stateRef.current.speechElements[currentPronunciationIndex].word,
													rate: -100,
													cb: () => {
														setIsPlayingSlow(false);
													},
												});
											}}
											className="p-2 rounded-full hover:bg-gray-200"
										>
											{isPlayingSlow ? (
												<MdStop className="text-xl" />
											) : (
												<MdVolumeMute className="text-xl" />
											)}
										</button>
										<button
											onClick={() => {
												if (isPlayingFast) return;
												setIsPlayingFast(true);
												speakTextAsync({
													text: stateRef.current.speechElements[currentPronunciationIndex].word,
													cb: () => {
														setIsPlayingFast(false);
													},
												});
											}}
											className="p-2 rounded-full hover:bg-gray-200"
										>
											{isPlayingFast ? (
												<MdPause className="text-xl" />
											) : (
												<MdVolumeUp className="text-xl" />
											)}
										</button>
									</div>

									<div className="absolute bottom-3 right-0 translate-y-1/2 flex gap-2">
										{stateRef.current.speechElements[currentPronunciationIndex].sentence && (
											<button
												onClick={() => {
													setShowSentence(prev => !prev);
													setShowDefinition(false);
													setImageState(prev => ({ ...prev, isVisible: false }));
												}}
												className="p-2 rounded-full hover:bg-gray-200"
											>
												<MdTextFields className="text-xl" />
											</button>
										)}

										{stateRef.current.speechElements[currentPronunciationIndex].definition && (
											<button
												onClick={() => {
													setShowDefinition(prev => !prev);
													setShowSentence(false);
													setImageState(prev => ({ ...prev, isVisible: false }));
												}}
												className="p-2 rounded-full hover:bg-gray-200"
											>
												<MdBook className="text-xl" />
											</button>
										)}

										{stateRef.current.speechElements[currentPronunciationIndex].sentence && (
											<button
												onClick={() => {
													handleGenerateImage();
													setShowSentence(false);
													setShowDefinition(false);
												}}
												className="p-2 rounded-full hover:bg-gray-200"
											>
												<MdImage className="text-xl" />
											</button>
										)}
									</div>
								</div>
							</div>

							{/* Display Areas */}
							{showSentence && (
								<AnimatePresence>
									<motion.div
										className="mb-8"
										variants={containerVariants}
										initial="hidden"
										animate="visible"
										exit="exit"
									>
										<p className="text-md font-medium mt-6">Example Sentence:</p>
										<motion.div className="p-3 rounded-lg">
											{(() => {
												const sentence = stateRef.current.speechElements[currentPronunciationIndex].sentence;
												const word = stateRef.current.speechElements[currentPronunciationIndex].word;

												// Use word boundaries \b in the regex to match exact words
												const regex = new RegExp(`\\b(${word})\\b`, 'i');
												const parts = sentence.split(regex);

												return parts.map((part: string, index: number) =>
													part.toLowerCase() === word.toLowerCase() ? (
														<span key={index} className="bg-yellow-200 font-semibold">
															{part}
														</span>
													) : (
														<span key={index}>{part}
														</span>
													));
											})()}
										</motion.div>
									</motion.div>
								</AnimatePresence>
							)}

							{/* Definition Display */}
							{showDefinition && (
								<AnimatePresence>
									<motion.div
										className="mb-8"
										variants={containerVariants}
										initial="hidden"
										animate="visible"
										exit="exit"
									>
										<p className="text-md font-medium mt-6">Definition:</p>
										<motion.div className="p-3 bg-gray-50 rounded-lg">
											{stateRef.current.speechElements[currentPronunciationIndex].definition}
										</motion.div>
									</motion.div>
								</AnimatePresence>
							)}

							{/* Image Display */}
							{imageState.isVisible && (
								<div className="mb-4">
									<p className="text-md font-medium">Image:</p>
									<div className="p-3 bg-gray-50 rounded-lg">
										{imageState.isLoading ? (
											<div className="w-full md:w-60 h-auto mx-auto flex items-center justify-center">
												<div className="animate-spin rounded-full h-12 w-12 border-b-2 border-gray-900" />
											</div>
										) : (
											<img
												src={imageState.url}
												alt="Word visualization"
												className="w-60 h-auto rounded-lg mx-auto"
											/>
										)}
									</div>
								</div>
							)}


							{/* Floating Action Button */}
							<div className={`mx-auto pt-10`}>
								<button
									onClick={() => setIsRecognizing(!isRecognizing)}
									className={`w-[70px] h-[70px] rounded-full flex items-center justify-center shadow-lg ${isRecognizing ? 'bg-red-600' : 'bg-blue-600'
										} hover:${isRecognizing ? 'bg-red-700' : 'bg-blue-700'
										} transition-colors`}
								>
									{isRecognizing ? (
										<div className="relative">
											<MdPause className="text-white text-3xl" />
											<div className="absolute -inset-2 border-4 border-white rounded-full animate-ping opacity-75"></div>
										</div>
									) : (
										<MdMic className="text-white text-3xl" />
									)}
								</button>
							</div>


						</div>
					)}

				</div>
				{/* Settings Component */}
				{showSettings && (
					<PronunciationSettings
						showSettings={showSettings}
						setShowSettings={setShowSettings}
						settings={settings}
						setSettings={setSettings}
					/>
				)}
			</main>
			<audio
				style={{ display: "none" }}
				ref={audioRef}
				src={resultsRef.current[currentRef.current]?.blobUrl || ""}
				onEnded={() => setAudioPlaying(false)}
			/>
		</div>
	);


};

export default PronunciationPracticePage;