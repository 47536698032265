import React, { useState, useCallback, useEffect, useRef } from 'react';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import { MdReplay } from 'react-icons/md';
import debounce from 'lodash/debounce';
import { useLiveTutor } from '../../../hooks/LiveTutorProvider';

import PronunciationPracticePage from '../whiteboard/Pronunciation';
import FillInTheBlanks from './vocabulary/FillInTheBlanks';
import { Dialogue } from '../../../types';
import { useStorage } from '../../../hooks/useStorage';
import { useLoading } from '../../../hooks/LoadingProvider';
import { useServer } from '../../../hooks/useServer';
import { useToast } from '../../../hooks/ToastProvider';
import NavigationArrows from '../../../components/NavigationArrows';

interface PronunciationLessonProps {
	pronunciationContent: any;
	onComplete?: () => void;
}

const Progress = ({ current, total }: { current: number; total: number }) => {
	const percentage = ((current + 1) / total) * 100;

	return (
		<div className="mb-6 p-6">
			<div className="flex justify-between text-sm text-gray-600 mb-2">
				<span>Progress</span>
				<span>{current + 1} of {total}</span>
			</div>
			<div className="w-full h-2 bg-gray-200 rounded-full">
				<div
					className="h-full bg-blue-500 rounded-full transition-all duration-300 ease-in-out"
					style={{ width: `${percentage}%` }}
				/>
			</div>
		</div>
	);
};

const PronunciationLesson: React.FC<PronunciationLessonProps> = ({
	pronunciationContent,
	onComplete
}) => {
	const { showLoading, hideLoading } = useLoading();
	const { showToast } = useToast();
	const { postPronunciationAssessmentResults } = useServer();
	const { loadBlobFromStorage, deleteFromStorage } = useStorage();

	const { systemMessageRef } = useLiveTutor();
	const [currentPronunciationIndex, setCurrentPronunciationIndex] = useState(0);
	const resultsRef = useRef<any[]>([]);

	useEffect(() => {
		let message = `You are a friendly tutor named Sarah. You are teaching English to beginners.
		
# INSTRUCTIONS:
This exercise is about pronunciation.

- Help the user practice pronouncing the words correctly

# WHAT THE USER SEES:
- Word: ${pronunciationContent.content[currentPronunciationIndex].word}`;

		systemMessageRef.current = message;
	}, [currentPronunciationIndex]);

	const handleNext = () => {
		if (currentPronunciationIndex < pronunciationContent.content.length - 1) {
			setCurrentPronunciationIndex(currentPronunciationIndex + 1);
		} else {
			console.log('Lesson completed!');
			submitPronunciationAssessment();
		}
	};

	const handlePrevious = () => {
		if (currentPronunciationIndex > 0) {
			setCurrentPronunciationIndex(currentPronunciationIndex - 1);
		}
	};


	const submitPronunciationAssessment = async () => {
		showLoading("Submitting results...");

		const audioPromises = resultsRef.current.map(
			(result) => result && loadBlobFromStorage(result.audio)
		);

		// Wait for all audio blobs to load
		Promise.all(audioPromises)
			.then(async (audioBlobs) => {
				// if assessment
				await postPronunciationAssessmentResults(
					resultsRef.current,
					pronunciationContent.content.map((word: any) => word.word),
					"session1",
					audioBlobs,
				);

				// Delete all audio blobs
				await Promise.all(
					resultsRef.current.map(
						(result) =>
							result && deleteFromStorage(result.audio)
					)
				);

				onComplete?.();
			})
			.catch((error) => {

				showToast("Error submitting results");
				console.error("Error loading audio blobs", error);
			}).finally(() => {
				hideLoading();
			});
	};



	return (
		<div className="max-w-2xl mx-auto md:p-6 mb-16 md:mb-0 md:mt-10 w-full md:w-4/5 	bg-white rounded-lg shadow-lg">
			<Progress
				current={currentPronunciationIndex}
				total={pronunciationContent.content.length}
			/>


			<PronunciationPracticePage
				pronunciationProgress={{
					name: "Practice Session",
					sessionId: "session1",
					speechElements: pronunciationContent.content,
					isAssessment: pronunciationContent.metric ? false : true,
					isFrequency: false,
					role: "student",
					interest: "general",
					video: pronunciationContent.video,
					metric: pronunciationContent.metric,
				}}
				onComplete={() => handleNext()}
				currentPronunciationIndex={currentPronunciationIndex}
				resultsRef={resultsRef}
			/>



			<NavigationArrows
				steps={pronunciationContent.content.length}
				currentSlide={currentPronunciationIndex}
				progressString={`${currentPronunciationIndex + 1}/${pronunciationContent.content.length}`}
				disabled={currentPronunciationIndex === 0}
				handleNext={handleNext}
				handlePrevious={handlePrevious}


			/>
		</div>
	);
};

export default PronunciationLesson;