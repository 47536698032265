import React, { useState, useCallback, useEffect } from 'react';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import { Lesson } from '../../../types';
import debounce from 'lodash/debounce';
import { useLiveTutor } from '../../../hooks/LiveTutorProvider';
import NavigationArrows from '../../../components/NavigationArrows';
import StepIndicator from '../../../components/StepIndicator';
import { useAzure } from '../../../hooks/useAzure';
import { useServer } from '../../../hooks/useServer';
import { useToast } from '../../../hooks/ToastProvider';
import { useLoading } from '../../../hooks/LoadingProvider';
import MessageList from '../../../components/roleplay/MessageList';
import ChatInput from '../../../components/roleplay/ChatInput';

import { Message, RoleplaySettingsProps, defaultRoleplaySettings } from '../../../types';
import LessonSteps from './Lesson';

interface AdvancedFlowProps {
	lesson: any;
	onComplete?: () => void;
}

const AdvancedFlow: React.FC<AdvancedFlowProps> = ({ lesson, onComplete }) => {
	console.log(lesson);

	const { systemMessageRef } = useLiveTutor();
	const { chat, generateLessonExercise } = useServer();
	const { showToast } = useToast();
	const [unblurredSet, setUnblurredSet] = useState<Set<number>>(new Set([0]));
	const [isPlayingIndex, setIsPlayingIndex] = useState(-1);
	const { speakTextAsync, stopTextAsync } = useAzure();
	const [settings, setSettings] = useState<RoleplaySettingsProps>(defaultRoleplaySettings);

	const [end, setEnd] = useState(false);

	const [currentStep, setCurrentStep] = useState(0);
	const [messages, setMessages] = useState<Message[]>([{
		role: 'system',
		content: `You are in a roleplay scenario with a user (who is practicing English).

## The User is given the following information:
${lesson.scenario}

## Your Instructions:
${lesson.llm_instructions}

Please respond to the user's messages in a natural and engaging way.

Use beginner level English with simple vocabulary and grammar.

You can end the question by saying "--End conversation--".`,
		scenario: lesson.scenario,

	},
	{
		role: 'assistant',
		content: lesson.first_message
	}]);
	const [lessonContent, setLessonContent] = useState<any>(null);
	const [exerciseContent, setExerciseContent] = useState<any>(null);
	const [input, setInput] = useState('');

	const steps = ['Conversation', 'Lesson'];

	const sendMessage = async (input: string) => {
		if (input.trim()) {
			const newMessage = { role: "user", content: input.trim() } as Message;
			setMessages(prev => [...prev, newMessage]);
			systemMessageRef.current += `User: ${input.trim()}\n`;

			try {
				let response = await chat([...messages, newMessage]);
				const endIndex = response.indexOf('--End conversation--');
				if (endIndex !== -1) {
					response = response.substring(0, endIndex);
					setEnd(true);
				}

				systemMessageRef.current += `AI: ${response}\n`;
				const responseMessage = { role: "assistant", content: response } as Message;
				setMessages((prev: any) => [...prev, responseMessage]);
			} catch (error) {
				console.error(error);
			}
		}
	};

	const updateSystemMessage = (slide: number) => {
		let message = `You are a friendly tutor named Sarah. You are teaching English to beginners.

# INSTRUCTIONS:
- The exercise has just changed! Please guide the user through the new exercise.
- Keep your responses VERY short and conversational.`;

		switch (slide) {
			case 0:
				message += `The user is given the following roleplay scenario: ${lesson.scenario}
The user will be chatting with another AI that has the following instructions: ${lesson.llm_instructions}
The user might ask you for help during the conversation.

## Conversation:
AI: ${lesson.first_message}`;
				break;
			case 1:
				message += `\n# CURRENT EXERCISE: Lesson
- Help the user understand the lesson content

# Lesson Content:
${lessonContent}`;

				break;
		}

		systemMessageRef.current = message;
	};

	useEffect(() => {
		updateSystemMessage(currentStep);
	}, [currentStep]);

	const handleNext = async () => {
		if (currentStep < steps.length - 1) {
			if (!end) {
				showToast('Please complete the task before moving on.');
				return;
			}

			setCurrentStep(currentStep + 1);
			if (currentStep === 0) {
				// Make API call to get lesson content
				try {
					const { exerciseContent, lessonContent } = await generateLessonExercise(messages, lesson.scenario, lesson.topic);
					setLessonContent(lessonContent);
					setExerciseContent(exerciseContent);
				} catch (error) {
					console.error('Error generating lesson:', error);
				}
			}
		} else {
			onComplete?.();
		}
	};

	const renderStep = () => {
		switch (currentStep) {

			case 0:

				return (
					<div className="flex flex-col mb-24">
						<div className="mb-6">
							<img
								src={lesson.scenario_image.src}
								alt={lesson.scenario_image.alt}
								className="w-full h-64 object-cover rounded-lg shadow-md"
							/>
						</div>

						<MessageList
							hasFeedback={false}
							scenario={{ messages, voiceId: lesson.voice_id }}
							settings={settings}
							profile={lesson.profile_image}
							unblurredSet={unblurredSet}
							setUnblurredSet={setUnblurredSet}
							isPlayingIndex={isPlayingIndex}
							setIsPlayingIndex={setIsPlayingIndex}
						/>

						<ChatInput
							input={input}
							setInput={setInput}
							inputType={settings.inputType}
							sendMessage={sendMessage}
						/>

						{end && (
							<div className="bg-gray-50 rounded-lg p-6 text-center">
								<p className="text-lg font-semibold mb-4">
									You have reached the end of the conversation.
								</p>
								<button
									onClick={handleNext}
									className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-6 rounded-lg transition duration-200"
								>
									Continue to Next Lesson
								</button>
							</div>
						)}
					</div>
				);

			case 1:
				return (
					<div className="bg-gray-50 rounded-lg p-6">
						{lessonContent ? (


							<LessonSteps steps={lessonContent.steps} title={lessonContent.title} onComplete={handleNext} />

						) : (
							<div className="flex justify-center items-center h-64">
								<div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500" />
							</div>
						)}
					</div>
				);

			case 2:
				return (
					<div className="bg-white rounded-lg p-6">
						{exerciseContent ? (
							<div className="prose max-w-none">
								<h2 className="text-2xl font-bold mb-4">Exercise</h2>
								{/* Render exercise content here */}
								{exerciseContent}
							</div>
						) : (
							<div className="flex justify-center items-center h-64">
								<div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500" />
							</div>
						)}
					</div>
				);

			default:
				return null;
		}
	};

	return (
		<div className="max-w-3xl mx-auto md:p-6 mb-12">
			<StepIndicator
				steps={steps}
				currentSlide={currentStep}
				setCurrentSlide={setCurrentStep}
			/>

			<div className="bg-white rounded-lg shadow-lg md:p-6 ">
				{renderStep()}
			</div>

			<NavigationArrows
				steps={steps}
				currentSlide={currentStep}
				disabled={currentStep === 0 && messages.length === 0}
				handleNext={handleNext}
				handlePrevious={() => setCurrentStep(currentStep - 1)}
			/>


		</div>
	);
};

export default AdvancedFlow;