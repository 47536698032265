import React, { Fragment, useState, useEffect } from 'react';
import { FiSettings, FiLogOut, FiUser, FiChevronLeft, FiMenu } from 'react-icons/fi';
import { FaFire } from 'react-icons/fa';
import { useServer } from '../../hooks/useServer';
import { useAuth } from '../../hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import { motion } from "framer-motion";
import { useLiveTutor } from '../../hooks/LiveTutorProvider';

interface HeaderProps {
	exercise?: string;
	back?: () => void;
}

const Header: React.FC<HeaderProps> = ({ exercise, back }) => {
	const { user } = useServer();
	const { currentUser, logout } = useAuth();
	const navigate = useNavigate();
	const [showMenu, setShowMenu] = useState(false);
	const [showMobileMenu, setShowMobileMenu] = useState(false);
	const { show } = useLiveTutor();

	useEffect(() => {
		const handleClickOutside = (event: any) => {
			if ((showMenu || showMobileMenu) &&
				event.target &&
				!event.target.closest('.profile-menu') &&
				!event.target.closest('.mobile-menu')) {
				setShowMenu(false);
				setShowMobileMenu(false);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [showMenu, showMobileMenu]);

	const xpForNextLevel = 1000;
	const xp = user?.xp || 0;
	const xpProgress = (xp % xpForNextLevel) / xpForNextLevel * 100;
	const level = Math.floor(xp / xpForNextLevel) + 1;

	return (
		<header className={`fixed top-0 left-0 right-0 z-50 md:bg-gradient-to-r md:from-indigo-600 md:to-purple-600 md:shadow-lg ${!show ? 'bg-gradient-to-r from-indigo-600 to-purple-600 shadow-lg' : ''} text-white`}>
			<div className="max-w-7xl mx-auto px-4 py-3">
				<div className="flex items-center justify-between">
					{/* Logo or Back Button */}
					{exercise ? (
						<button
							onClick={() => back && back()}
							className="hidden md:flex items-center space-x-2 hover:bg-white/10 rounded-lg px-3 py-2 transition-colors"
						>
							<FiChevronLeft className="text-xl" />
							<span className="font-medium hidden sm:inline">Back</span>
						</button>
					) : (
						<div className="hidden md:flex items-center">
							<img src="logo.png" alt="Logo" className="w-6 h-6 sm:w-8 sm:h-8" />
							<img src="wordmark-black.png" alt="Wordmark" className="h-5 ml-2 sm:h-6" />
						</div>
					)}

					{/* Center Content - Hidden on mobile, visible on desktop */}
					<div className="hidden md:flex items-center space-x-8">
						{!exercise && (
							<>
								<div className="flex items-center space-x-2">
									<div className="flex items-center bg-white/10 rounded-lg px-3 py-1">
										<FaFire className="text-orange-400 mr-2" />
										<span className="font-semibold">{user?.gamification?.streak || 0} day streak</span>
									</div>
								</div>

								<div className="flex flex-col w-48">
									<div className="flex justify-between text-sm mb-1">
										<span>Level {level}</span>
										<span>{(user?.xp || 0) % xpForNextLevel}/{xpForNextLevel} XP</span>
									</div>
									<div className="h-2 bg-white/20 rounded-full overflow-hidden">
										<motion.div
											className="h-full bg-gradient-to-r from-green-400 to-emerald-500 rounded-full"
											initial={{ width: 0 }}
											animate={{ width: `${xpProgress}%` }}
											transition={{ duration: 1, ease: "easeOut" }}
										/>
									</div>
								</div>
							</>
						)}
					</div>


					{exercise && (
						<h1 className="hidden md:block text-lg sm:text-2xl items-center font-semibold truncate max-w-[200px] sm:max-w-none">
							{exercise}
						</h1>
					)}

					{/* Right Side - User Profile */}
					<div className="flex items-center space-x-2 sm:space-x-4">


						<div className="relative profile-menu">
							<button
								onClick={() => setShowMenu(!showMenu)}
								className="hidden md:flex items-center space-x-2 focus:outline-none hover:opacity-80 transition-opacity"
							>
								<div className="w-8 h-8 sm:w-10 sm:h-10 rounded-full overflow-hidden border-2 border-white/20">
									{currentUser?.photoURL ? (
										<img
											src={currentUser?.photoURL}
											alt={currentUser?.displayName || 'User'}
											className="w-full h-full object-cover"
										/>
									) : (
										<div className="w-full h-full bg-white/10 flex items-center justify-center">
											<span className="text-lg font-semibold">
												{(currentUser?.displayName || 'U').charAt(0)}
											</span>
										</div>
									)}
								</div>
							</button>

							{showMenu && (
								<div className="absolute right-0 mt-3 w-56 bg-white rounded-xl shadow-xl py-2 text-gray-700 border border-gray-100">
									<div className="px-4 py-2 border-b border-gray-100">
										<p className="font-medium text-gray-900">{currentUser?.displayName}</p>
										<p className="text-sm text-gray-500">{currentUser?.email}</p>
									</div>
									<button
										className="w-full px-4 py-2.5 text-left hover:bg-gray-50 flex items-center transition-colors"
										onClick={() => { navigate('/profile'); setShowMenu(false); }}
									>
										<FiUser className="mr-3 text-gray-400" />
										Profile
									</button>
									<button
										className="w-full px-4 py-2.5 text-left hover:bg-gray-50 flex items-center transition-colors"
										onClick={() => { setShowMenu(false); }}
									>
										<FiSettings className="mr-3 text-gray-400" />
										Settings
									</button>
									<button
										className="w-full px-4 py-2.5 text-left hover:bg-red-50 flex items-center text-red-600 transition-colors"
										onClick={() => logout()}
									>
										<FiLogOut className="mr-3" />
										Logout
									</button>
								</div>
							)}
						</div>
					</div>
				</div>

				{/* Mobile Menu */}
				<div className="md:hidden flex items-center justify-between">
					{/* Back Button */}
					{exercise && (
						<button
							onClick={() => back && back()}
							className="flex items-center space-x-2 hover:bg-white/10 rounded-lg px-3 py-2 transition-colors"
						>
							<FiChevronLeft className="text-xl" />

						</button>
					)}




					<div className="flex items-center bg-white/10 rounded-lg px-3 py-1">
						<FaFire className="text-orange-400 mr-2" />
						<span className="font-semibold">{user?.gamification?.streak || 0} day streak</span>
					</div>
					<div className="flex flex-col w-32">
						<div className="flex justify-between text-sm mb-1">
							<span>{(user?.xp || 0) % xpForNextLevel}/{xpForNextLevel} XP</span>
						</div>
						<div className="h-2 bg-white/20 rounded-full overflow-hidden">
							<motion.div
								className="h-full bg-gradient-to-r from-green-400 to-emerald-500 rounded-full"
								initial={{ width: 0 }}
								animate={{ width: `${xpProgress}%` }}
								transition={{ duration: 1, ease: "easeOut" }}
							/>
						</div>
					</div>
				</div>
			</div>
		</header>
	);
};

export default Header;