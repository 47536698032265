import React, { Fragment, useState, useEffect } from "react";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { RiLockLine, RiRocketLine, RiMailLine, RiCheckLine } from "react-icons/ri";

import { useAuth } from "../hooks/useAuth";
import { useToast } from "../hooks/ToastProvider";

const Welcome: React.FC = () => {

	const { currentUser, refreshClaims, claims } = useAuth();
	const [submitStatus, setSubmitStatus] = useState("idle");
	const [accessChecking, setAccessChecking] = useState(false);
	const navigate = useNavigate();
	const { showToast } = useToast();

	useEffect(() => {
		if (claims?.admin || claims?.betaUser) {
			navigate("/liveTutor");
		}
	}, [claims]);

	const handleSubmit = async (e: any) => {
		if (!currentUser?.email) return;
		if (submitStatus === "processing" || submitStatus === "success") return;

		console.log("Submitting access request...");
		console.log(currentUser);
		e.preventDefault();
		setSubmitStatus("processing");

		const data = new FormData();
		data.append("email", currentUser.email);
		data.append("sheetName", "FluentFutureBeta");

		try {
			const response = await fetch(
				"https://script.google.com/macros/s/AKfycbzUnCt-XonKsqeYtquXUtLduIdKkmxm01ZBKwuh9K2KyJ6MH-96rTpFC4kMy5jEVvaI/exec",
				{
					method: "POST",
					body: data,
				}
			);
			if (response.ok) {
				setSubmitStatus("success");
			} else {
				setSubmitStatus("error");
			}
		} catch (error) {
			console.error("Error:", error);
			setSubmitStatus("error");
		}
	};

	const handleCheckAccess = async () => {
		setAccessChecking(true);
		try {
			const claims = await refreshClaims();
			if (claims?.admin || claims?.betaUser) {
				navigate("/onboarding");
			} else {
				showToast("You do not have access yet.");
			}
		} catch (error) {
			console.error("Error checking access:", error);
			showToast("An error occurred while checking access.");
		} finally {
			setAccessChecking(false);
		}
	};

	const renderButtonContent = () => {
		switch (submitStatus) {
			case "processing":
				return <div className="animate-spin h-5 w-5 border-2 border-white rounded-full border-t-transparent" />;
			case "success":
				return (
					<div className="flex items-center">
						<RiCheckLine className="mr-2" />
						Access Requested
					</div>
				);
			default:
				return (
					<div className="flex items-center">
						<RiRocketLine className="mr-2" />
						Request Access
					</div>
				);
		}
	};

	return (
		<div className="min-h-screen">
			<main className="flex flex-col items-center justify-center min-h-screen text-center px-4 bg-gradient-to-b from-blue-100 to-purple-100">
				<motion.div
					initial={{ scale: 0.8, opacity: 0 }}
					animate={{ scale: 1, opacity: 1 }}
					transition={{ duration: 0.5 }}
					className="mb-8"
				>
					<img
						src="logo.png"
						alt="Beta Access"
						className="w-48 h-48 rounded-full shadow-xl border-4 border-white"
					/>
				</motion.div>

				<motion.div
					initial={{ y: 20, opacity: 0 }}
					animate={{ y: 0, opacity: 1 }}
					transition={{ delay: 0.2, duration: 0.5 }}
					className="mb-4"
				>
					<h1 className="text-4xl font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-purple-600 to-blue-500">
						Welcome to Our Closed Beta!
					</h1>
				</motion.div>

				<motion.div
					initial={{ y: 20, opacity: 0 }}
					animate={{ y: 0, opacity: 1 }}
					transition={{ delay: 0.4, duration: 0.5 }}
					className="mb-8"
				>
					<p className="text-xl text-gray-600">
						We're excited to have you on board. Request access to start
						exploring our exclusive features.
					</p>
				</motion.div>

				<motion.div
					initial={{ y: 20, opacity: 0 }}
					animate={{ y: 0, opacity: 1 }}
					transition={{ delay: 0.6, duration: 0.5 }}
					className="mb-8"
				>
					<button
						className={`px-8 py-3 rounded-full font-semibold text-white transition-colors ${submitStatus === "success"
							? "bg-green-500"
							: submitStatus === "error"
								? "bg-red-500"
								: "bg-blue-500"
							} ${(submitStatus === "processing" ||
								submitStatus === "success" ||
								!currentUser?.email) &&
							"opacity-50 cursor-not-allowed"
							}`}
						onClick={handleSubmit}
						disabled={
							submitStatus === "processing" ||
							submitStatus === "success" ||
							!currentUser?.email
						}
					>
						{renderButtonContent()}
					</button>
				</motion.div>

				{submitStatus === "success" && (
					<Fragment>
						<motion.div
							initial={{ y: 20, opacity: 0 }}
							animate={{ y: 0, opacity: 1 }}
							transition={{ delay: 0.2, duration: 0.5 }}
							className="mt-4"
						>
							<p className="flex items-center justify-center text-gray-600">
								<RiMailLine className="mr-2" />
								We'll notify you via email once your access is granted
							</p>
						</motion.div>

						<motion.div
							initial={{ y: 20, opacity: 0 }}
							animate={{ y: 0, opacity: 1 }}
							transition={{ delay: 0.8, duration: 0.5 }}
							className="mt-8"
						>
							<button
								className={`px-8 py-3 rounded-full font-semibold text-white bg-purple-500 transition-colors ${accessChecking && "opacity-50 cursor-not-allowed"
									}`}
								onClick={handleCheckAccess}
								disabled={accessChecking}
							>
								{accessChecking ? (
									<div className="animate-spin h-5 w-5 border-2 border-white rounded-full border-t-transparent" />
								) : (
									<div className="flex items-center">
										<RiLockLine className="mr-2" />
										Check Access
									</div>
								)}
							</button>
						</motion.div>
					</Fragment>
				)}
			</main>
		</div>
	);
};

export default Welcome;